// extracted by mini-css-extract-plugin
export var mintingForm = "style-module--mintingForm--2PoaN";
export var restoreInput = "style-module--restoreInput--3lr3K";
export var restoreError = "style-module--restoreError--11xMc";
export var image = "style-module--image--gybea";
export var remove = "style-module--remove--7j3YL";
export var faq = "style-module--faq--JbKog";
export var assetGroup = "style-module--assetGroup--1UQwX";
export var assetKey = "style-module--assetKey--O22tH";
export var assetValue = "style-module--assetValue--1BNRu";
export var assetValueShort = "style-module--assetValueShort--1oEMH";
export var assetRemove = "style-module--assetRemove--38FUW";